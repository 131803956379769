<template>
  <div>
    <el-form class="zwx-form edit-form" key="msForm" ref="msForm" :model="msForm" :rules="rules" label-position="right" @submit.native.prevent>
      <base-headline title="基本信息" class="no-top" />
      <div style="margin-top:10px">
        <div class="condition-row">
          <el-form-item label-width="110px" label="编码：" prop="codeNo">
            <el-input style="width:441px !important" class="zwx-input" v-model.trim="msForm.codeNo" placeholder="请输入" clearable />
          </el-form-item>
        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="层级编码：" prop="levelCodeNo">
            <el-input style="width:441px !important" class="zwx-input" v-model.trim="msForm.levelCodeNo" placeholder="请输入" clearable />
          </el-form-item>
        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="国标编码：" prop="nationalStandardCode">
            <el-input style="width:441px !important" class="zwx-input" v-model.trim="msForm.nationalStandardCode" maxlength="20" placeholder="请输入" clearable />
          </el-form-item>
        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="行业名称：" prop="industryName">
            <el-input style="width:441px !important" class="zwx-input" v-model.trim="msForm.industryName" placeholder="请输入" clearable />
          </el-form-item>
        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="全称：" prop="fullName">
            <el-input style="width:441px !important" class="zwx-input" v-model.trim="msForm.fullName" placeholder="根据层级编码自行生成!" clearable disabled/>
          </el-form-item>
        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="分类级别：" prop="industryLevel">
            <el-radio-group style="min-width:260px !important;" class="zwx-radio-group" v-model="msForm.industryLevel">
              <el-radio class="zwx-radio" :label="1">门类</el-radio>
              <el-radio class="zwx-radio" :label="2">大类</el-radio>
              <el-radio class="zwx-radio" :label="3">中类</el-radio>
              <el-radio class="zwx-radio" :label="4">小类</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="condition-row">
          <el-form-item label-width="110px" label="风险分类：" prop="riskClassification">
            <el-radio-group style="min-width:260px !important;" class="zwx-radio-group" v-model="msForm.riskClassification">
              <el-radio class="zwx-radio" :label="1">严重</el-radio>
              <el-radio class="zwx-radio" :label="2">一般</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="saveOrUpdateIndustryClassification">保存</el-button>
    </fixed-footer>
  </div>
</template>

<script>
export default {
  name: 'IndustryClassificationEdit',
  data() {
    return {
      api: this.$store.state.api,
      view: true,
      type: this.$route.params.type,
      rid: '',
      msForm: {
        rid: '',
        industryName: '',
        codeNo: '',
        levelCodeNo: '',
        nationalStandardCode:'',
        industryLevel: '',
        fullName: '',
        riskClassification: '',
        ifEnable: '',
      },
      loading: null,
      // 校验规则
      rules: {
        industryName: [{ required: true, message: '行业名称不得为空', trigger: ['blur'] }],
        codeNo: [{ required: true, message: '编码不得为空', trigger: ['blur'] }],
        levelCodeNo: [{ required: true, message: '层级编码不得为空', trigger: ['blur'] }],
        nationalStandardCode: [{ required: true, message: '国标编码不得为空', trigger: ['blur'] }],
        industryLevel: [{ required: true, message: '请选择分类级别', trigger: ['change'] }],
        riskClassification: [{ required: true, message: '请选择风险分类', trigger: ['change'] }],
      },
      msFormOld: null,
    }
  },
  created() {},
  mounted() {
    // 判断添加 还是编辑
    this.pageRouter()
  },
  activated() {
    if (this.$route.params.fresh) this.query()
  },
  computed: {},
  methods: {
    /**
     * 页面路由回调
     */
    pageRouter() {
      if (this.type === 'add') {
        this.$emit('breadcrumb', '添加', true)
        this.msFormOld = JSON.stringify(this.msForm)
      }
      if (this.type === 'edit') {
        this.$emit('breadcrumb', '编辑', true)
        this.rid = this.$route.params.rid
        this.getDetail(this.rid)
      }
    },
    /**
     * 获取详情
     */
    getDetail(rid) {
      let data = {
        rid: rid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/oh/repository/getIndustryClassificationDetail-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.msForm.rid = data.industryClassification.rid
            this.msForm.industryName = data.industryClassification.industryName
            this.msForm.codeNo = data.industryClassification.codeNo
            this.msForm.levelCodeNo = data.industryClassification.levelCodeNo
            this.msForm.fullName = data.industryClassification.fullName
            this.msForm.industryLevel = data.industryClassification.industryLevel
            this.msForm.nationalStandardCode = data.industryClassification.nationalStandardCode
            this.msForm.riskClassification = data.industryClassification.riskClassification
            this.msForm.ifEnable = data.industryClassification.ifEnable
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 保存或更新
     */
    saveOrUpdateIndustryClassification() {
      var industryClassNo = ''
      var industryBigNo = ''
      var industryMiddleNo = ''
      var industryCateNo = ''
      if (this.msForm.levelCodeNo.split('.').length == 1) {
        industryClassNo = this.msForm.levelCodeNo
      }
      if (this.msForm.levelCodeNo.split('.').length == 2) {
        industryClassNo = this.msForm.levelCodeNo.split('.')[0]
        industryBigNo = this.msForm.levelCodeNo.split('.')[1]
      }
      if (this.msForm.levelCodeNo.split('.').length == 3) {
        industryClassNo = this.msForm.levelCodeNo.split('.')[0]
        industryBigNo = this.msForm.levelCodeNo.split('.')[1]
        industryMiddleNo = this.msForm.levelCodeNo.split('.')[2]
      }
      if (this.msForm.levelCodeNo.split('.').length == 4) {
        industryClassNo = this.msForm.levelCodeNo.split('.')[0]
        industryBigNo = this.msForm.levelCodeNo.split('.')[1]
        industryMiddleNo = this.msForm.levelCodeNo.split('.')[2]
        industryCateNo = this.msForm.levelCodeNo.split('.')[3]
      }
      let data = {
        rid: this.rid || '',
        industryName: this.msForm.industryName,
        codeNo: this.msForm.codeNo,
        levelCodeNo: this.msForm.levelCodeNo,
        industryLevel: this.msForm.industryLevel,
        nationalStandardCode: this.msForm.nationalStandardCode,
        riskClassification: this.msForm.riskClassification,
        ifEnable: this.msForm.riskClassification.ifEnable,
        industryClassNo: industryClassNo,
        industryBigNo: industryBigNo,
        industryMiddleNo: industryMiddleNo,
        industryCateNo: industryCateNo,
      }
      this.$refs['msForm'].validate(valid => {
        if (valid) {
          this.$emit('loading', true)
          this.$system.postJson(
            this.api + '/oh/repository/saveOrUpdateIndustryClassification-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type == '00') {
                this.$system.notify('成功', data.mess, 'success')
                this.$router.push({ name: 'IndustryClassification', params: { fresh: true } })
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            data => {
              this.$emit('loading', false)
              this.$system.notify('错误', data.mess, 'error')
            }
          )
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped></style>
