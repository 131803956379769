var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "msForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msForm,
            rules: _vm.rules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("base-headline", {
            staticClass: "no-top",
            attrs: { title: "基本信息" }
          }),
          _c("div", { staticStyle: { "margin-top": "10px" } }, [
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "编码：",
                      prop: "codeNo"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "441px !important" },
                      attrs: { placeholder: "请输入", clearable: "" },
                      model: {
                        value: _vm.msForm.codeNo,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msForm,
                            "codeNo",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msForm.codeNo"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "层级编码：",
                      prop: "levelCodeNo"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "441px !important" },
                      attrs: { placeholder: "请输入", clearable: "" },
                      model: {
                        value: _vm.msForm.levelCodeNo,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msForm,
                            "levelCodeNo",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msForm.levelCodeNo"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "国标编码：",
                      prop: "nationalStandardCode"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "441px !important" },
                      attrs: {
                        maxlength: "20",
                        placeholder: "请输入",
                        clearable: ""
                      },
                      model: {
                        value: _vm.msForm.nationalStandardCode,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msForm,
                            "nationalStandardCode",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msForm.nationalStandardCode"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "行业名称：",
                      prop: "industryName"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "441px !important" },
                      attrs: { placeholder: "请输入", clearable: "" },
                      model: {
                        value: _vm.msForm.industryName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msForm,
                            "industryName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msForm.industryName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "全称：",
                      prop: "fullName"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "441px !important" },
                      attrs: {
                        placeholder: "根据层级编码自行生成!",
                        clearable: "",
                        disabled: ""
                      },
                      model: {
                        value: _vm.msForm.fullName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msForm,
                            "fullName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "msForm.fullName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "分类级别：",
                      prop: "industryLevel"
                    }
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "zwx-radio-group",
                        staticStyle: { "min-width": "260px !important" },
                        model: {
                          value: _vm.msForm.industryLevel,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "industryLevel", $$v)
                          },
                          expression: "msForm.industryLevel"
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 1 } },
                          [_vm._v("门类")]
                        ),
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 2 } },
                          [_vm._v("大类")]
                        ),
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 3 } },
                          [_vm._v("中类")]
                        ),
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 4 } },
                          [_vm._v("小类")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "condition-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "风险分类：",
                      prop: "riskClassification"
                    }
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "zwx-radio-group",
                        staticStyle: { "min-width": "260px !important" },
                        model: {
                          value: _vm.msForm.riskClassification,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "riskClassification", $$v)
                          },
                          expression: "msForm.riskClassification"
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 1 } },
                          [_vm._v("严重")]
                        ),
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: 2 } },
                          [_vm._v("一般")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: { click: _vm.saveOrUpdateIndustryClassification }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }